<template>
  <title-link-wrapper
    :title="$t('components.singleOrder.title')"
    :to="{ name: isSysAdmin ? 'SysadminOrders' : 'SalesmanOrders' }"
  >
    <v-progress-linear v-if="loading" :indeterminate="true" class="mb-3" />
    <v-container v-else>
      <order-details v-on:updateOrder="onUpdateOrder" :order="order" :company="company" />
      <p class="my-0 text-center text-overline" v-if="customPricesInOrder">
        {{ $t('components.singleOrder.sysAdminWarning') }}
      </p>
      <v-container class="white pa-4">
        <v-row>
          <v-col>
            <v-data-table
              :headers="headers"
              :items="products"
              :items-per-page="10"
              class="elevation-0"
              :sortBy="sortBy"
              :sortDesc="sortDesc"
              :header-props="tableHeaderProps"
              @update:sort-by="sortByFunc"
              @update:sort-desc="sortDescFunc"
              :no-data-text="this.$t('components.myDataTable.noDataAvailable')"
            >
              <template v-slot:[`item.standardPrice`]="{ item }">
                <span>{{ formatCurrency(item.standardPrice, order.currency, false) }}</span>
              </template>
              <template v-slot:[`item.totalPriceItem`]="{ item }">
                <span>{{
                  formatCurrency(
                    item.specialTotalPrice ? item.specialTotalPrice : totalPrice(item),
                    order.currency,
                    false
                  )
                }}</span>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-divider> </v-divider>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div v-if="isVisible && isSendInvoiceVisible">
              <h4>{{ $t('components.singleOrder.sendAs') }}</h4>
              <v-select
                v-model="selectedFVAction"
                :items="whichActionList"
                item-text="text"
                hide-details
                :placeholder="$t('components.singleOrder.chooseInvoiceAction')"
                outlined
                class="mt-2"
              ></v-select></div
          ></v-col>
          <price-details
            :totalPriceGross="totalPriceGross"
            :VAT="VAT"
            :totalPrice="order.totalPrice"
            :exchangeRate="order.currency"
            :shouldCalculateByCurrency="false"
            :orderDiscounts="order.discounts ? order.discounts : []"
          />
        </v-row>
        <v-row v-if="isVisible">
          <v-col cols="12" md="7">
            <div v-if="isSendInvoiceVisible">
              <primary-button :onClick="callFVFunc" :loading="buttonLoading">{{
                $t('helpers.send')
              }}</primary-button>
              <p class="mt-2">*{{ $t('components.singleOrder.docSent') }}</p>
            </div>
          </v-col>
          <v-col cols="12" md="5" class="text-right">
            <primary-button :onClick="generateOrderPdf" :loading="buttonLoading">{{
              $t('components.singleOrder.generatePdf')
            }}</primary-button>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </title-link-wrapper>
</template>
<script>
import TitleLinkWrapper from '../../../components/TitleLinkWrapper.vue';
import OrderService from '../../../services/OrderService';
import PrimaryButton from '../../../components/buttons/PrimaryButton.vue';
import { formatCurrency } from '@/mixins/formatCurrency';
import { countriesCodeHandler } from '@/mixins/countriesCodeHandler';
import { ORDER_STATUSES } from '@/static/orderStatuses';
import OrderDetails from '../../../components/OrderDetails.vue';
import PriceDetails from '../../../components/PriceDetails.vue';
import { sortHandler } from '@/mixins/sortHandler';
import { calculateGrossPrice } from '@/utils/priceUtils';
import { vatMultipliers } from '@/static/vatMultipliers';
import FileSaver from 'file-saver';
import VueI18n from '../../../plugins/i18n';
import { permissionRolesMixin } from '@/mixins/permissionRolesMixin';
import { productNamesInOrderMixin } from '@/mixins/productNamesInOrderMixin';
import { tableHeaderProps } from '@/mixins/tableHeaderProps';

export default {
  components: { TitleLinkWrapper, PrimaryButton, OrderDetails, PriceDetails },
  name: 'SingleOrder',
  mixins: [
    formatCurrency,
    sortHandler,
    countriesCodeHandler,
    permissionRolesMixin,
    tableHeaderProps,
    productNamesInOrderMixin,
  ],
  data() {
    return {
      orderStatuses: ORDER_STATUSES(),
      selectedFVAction: {},
      orderId: '',
      loading: true,
      products: [],
      order: '',
      buttonLoading: false,
      salesmanDiscount: 0,
    };
  },
  watch: {
    '$i18n.locale'() {
      this.products = this.mapProductNames(this.products);
    },
  },

  computed: {
    proFVActionList() {
      return [
        {
          text: VueI18n.t('components.singleOrder.invoiceActions.proformaClient'),
          func: () => {
            this.sentPaidOrNotPaidFVStatusForClient(this.order);
          },
        },
        {
          text: VueI18n.t('components.singleOrder.invoiceActions.proformaAdmin'),
          func: () => {
            this.sentPaidOrNotPaidFVStatusForAdmin(this.order);
          },
        },
      ];
    },
    FVActionList() {
      return [
        {
          text: VueI18n.t('components.singleOrder.invoiceActions.invoiceClient'),
          func: () => {
            this.changeOrderStatus(this.orderStatuses.FOR_CLIENT_FV.id);
          },
        },
        {
          text: VueI18n.t('components.singleOrder.invoiceActions.invoiceAdmin'),
          func: () => {
            this.changeOrderStatus(this.orderStatuses.FOR_ADMIN_FV.id);
          },
        },
      ];
    },
    headers() {
      return [
        {
          text: VueI18n.t('helpers.code'),
          align: 'start',
          value: 'code',
        },
        { text: VueI18n.t('helpers.filterName'), value: 'nameByLang' },
        {
          text: `${VueI18n.t('helpers.price')} (${VueI18n.t('helpers.net')})`,
          value: 'standardPrice',
        },
        { text: VueI18n.t('helpers.piecesAmount'), value: 'quantity' },
        {
          text: `${VueI18n.t('helpers.sum')} (${VueI18n.t('helpers.net')})`,
          value: 'totalPriceItem',
        },
      ];
    },

    isVisible() {
      return this.order.status?.id !== this.orderStatuses.CANCELLED.id;
    },
    isSendInvoiceVisible() {
      return !this.foreignDelivery(this.order.delivery.countryCode);
    },
    company() {
      return this.order.company ? this.order.company : {};
    },
    VAT() {
      return this.totalPriceGross - this.order.totalPrice;
    },
    totalPriceGross() {
      return calculateGrossPrice(this.order.totalPrice, vatMultipliers.VAT23.value);
    },
    whichActionList() {
      return this.orderStatuses.SENT.id === this.order.status?.id
        ? this.FVActionList
        : this.proFVActionList;
    },
    customPricesInOrder() {
      return this.order.specialTotalPrice || this.products.some((pr) => pr.specialTotalPrice);
    },
  },
  methods: {
    sentPaidOrNotPaidFVStatusForAdmin(order) {
      if (order.status.id === this.orderStatuses.NEW_ORDER.id) {
        this.changeOrderStatus(this.orderStatuses.FOR_ADMIN_NOT_PAID_PRO_FV.id);
      } else {
        this.changeOrderStatus(this.orderStatuses.FOR_ADMIN_PAID_PRO_FV.id);
      }
    },
    sentPaidOrNotPaidFVStatusForClient(order) {
      if (order.status.id === this.orderStatuses.NEW_ORDER.id) {
        this.changeOrderStatus(this.orderStatuses.FOR_CLIENT_NOT_PAID_PRO_FV.id);
      } else {
        this.changeOrderStatus(this.orderStatuses.FOR_CLIENT_PAID_PRO_FV.id);
      }
    },
    async changeOrderStatus(status_id) {
      try {
        this.buttonLoading = true;
        const response = await OrderService.updateStatus(this.orderId, status_id);
        if (response.status === 200) {
          this.order.status = response.data;
        }

        this.$toasted.global.success({
          message: VueI18n.t('components.singleOrder.toasted.changeOrderStatusFun'),
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.buttonLoading = false;
      }
    },
    callFVFunc() {
      const selectedAction = this.whichActionList.find(
        (action) => action.text === this.selectedFVAction
      );

      if (selectedAction.func) {
        selectedAction.func();
      } else {
        this.$toasted.global.error({
          message: VueI18n.t('components.singleOrder.toasted.callFVFuncFunc'),
        });
      }
    },
    async getOrder() {
      try {
        this.loading = true;
        const response = await OrderService.getSysadminOrder(this.orderId);
        this.order = response.data;

        this.products = this.mapProductNames(response.data.items);
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    totalPrice(item) {
      const totalPrice = item.quantity * item.standardPrice;
      return totalPrice.toFixed(2);
    },
    async onUpdateOrder() {
      await this.getOrder();
    },
    async generateOrderPdf() {
      try {
        const res = await OrderService.generateOfferPdf(this.orderId);

        FileSaver.saveAs(
          res,
          `${VueI18n.t('components.singleOrder.orderNumber')} ${this.order.baselinkerId}.pdf`
        );
        this.$toasted.global.success({
          message: VueI18n.t('components.singleOrder.toasted.generateOrderPdfFunc'),
        });
      } catch (err) {
        console.error(err);
      }
    },
  },
  async activated() {
    this.orderId = this.$route.params.orderId;
    await this.getOrder();
  },
};
</script>
<style lang="scss" scoped></style>
